<svg viewBox="0 0 1400 418.55" style="max-width: 480px; width: 90%; margin: auto;">
	<defs>
		<style>
			.cls-1 {
				fill: #fff;
			}
		</style>
	</defs>
	<g>
		<path class="cls-1" d="M1392.41,254.3c-8.76-21.41-22-39.29-37.36-55.24-17.1-17.71-37.92-30.4-58.24-43.76a160.23,160.23,0,0,1-16.42-11.86c-18.7-16.07-12.53-46.62,2.26-69a35,35,0,0,1,5.47-6.07c3.19-2.93,10.88-5.72,15.06-3.7,3.87,1.87,5,5.54,4.37,9.94-1,7.14-1.55,14.39-3.47,21.38-6.68,24.38,2.88,45.15,26.81,47.84,15.4,1.71,30.87-2.58,40.95-20.52,5.3-9.44,7.61-19.46,9.1-29.69,5.94-40.55-12.38-69.86-45.42-85.53-20-9.48-41.48-10.3-64.59-4.12-42.88,11.49-80,63-78.31,107.32.82,21.76,3.2,57.5,55.11,97.72,19.61,15.19,38.66,30.65,53.94,50.59a69.89,69.89,0,0,1,10.44,17.88,15.55,15.55,0,0,1-4.22,17.58,13.8,13.8,0,0,1-16.83.55c-4.1-3.07-7-7.35-9.75-11.78-4.18-6.81-9.44-12.57-14.92-18.14-6.09-6.19-43.93-29.54-68.62,12.82-8.32,14.26-10.54,29.79-9.41,45.32,1.51,21.07,9.71,38.09,23.24,51.7,23.17,23.28,52.36,32.49,86.62,29.23a101,101,0,0,0,10.68-1.28c22.66-4.9,43.47-14.84,60-34.25C1398.5,334.39,1408.38,293.39,1392.41,254.3Z" />
		<path class="cls-1" d="M1151.34,231.71c-56-14.35-44.58,65.47-83.91,64.18-22.56-.74-45.2-54.44-46.84-90.49-3.47-75.37,32.84-127.55,55.11-127,20.63.55,25.92,22.74,26,30.26-.85,23.12-6.62,30.49-30.71,31.85-27.45,1.54-37.46,24.19-35.84,39.61s18.3,33.07,38.09,34.78c71.46,6.14,116.92-75.63,86.16-151.81-.14-.36-.22-.74-.37-1.1a103.32,103.32,0,0,0-8.89-17.79l-.24-.44c-.06-.09-.13-.13-.19-.22-14.13-22-41.25-37.38-72.65-38.23C989,3,938,106.32,937.12,197.2c-1,115.56,55.17,207,128.14,205.28C1168.76,400.06,1201.39,244.53,1151.34,231.71Z" />
		<path class="cls-1" d="M886.36,201.66C869.89,184,849.53,171.3,829.7,158a152.37,152.37,0,0,1-16-11.85c-18.11-16.05-10.89-46.58,4.66-68.93a35,35,0,0,1,5.69-6.05c3.28-2.94,11.06-5.72,15.17-3.7,3.79,1.87,4.75,5.53,4,9.94-1.22,7.12-2,14.35-4.21,21.34-7.52,24.34,1.31,45.09,25.11,47.78,15.32,1.72,30.93-2.58,41.62-20.5,5.63-9.42,8.26-19.44,10.11-29.65,7.35-40.51-9.92-69.77-42.38-85.41-19.62-9.47-41-10.31-64.35-4.13-43.22,11.47-82.09,62.9-82,107.19.06,21.74,1.21,57.42,51.66,97.59,19.06,15.19,37.52,30.61,52.11,50.53A67.57,67.57,0,0,1,840.73,280a15.87,15.87,0,0,1-4.82,17.56,13.82,13.82,0,0,1-16.83.53c-4-3.05-6.78-7.33-9.34-11.74-3.93-6.81-9-12.57-14.27-18.12-5.84-6.19-42.84-29.51-69,12.8-8.8,14.24-11.55,29.75-11,45.26.79,21,8.4,38.05,21.43,51.64,22.31,23.24,51.16,32.44,85.49,29.18,3.57-.4,7.14-.5,10.71-1.26,22.8-4.9,43.93-14.83,61.1-34.22,30.78-34.78,42.1-75.73,27.5-114.78C913.72,235.45,901.19,217.59,886.36,201.66Z" />
		<path class="cls-1" d="M402.57,89.39c1.89-20,4.74-39.52-2.44-58.08-7-18.28-23.39-25.84-42-19.39C312.6,27.76,274.72,164.48,252.28,270.14c-1.18-9.33-3.8-23.2-6.39-41.5-.63-4.39-1.26-9-1.85-13.95a652.42,652.42,0,0,1-4.76-92c.5-10.49,1.26-21.6,2.37-33.34,1.89-20,6.64-39.52-.53-58.08-7-18.28-25.31-25.84-43.89-19.39C163.49,23.65,134,101.68,111.81,184.27c-20.57,56-46.18,113-86.82,148.06C-50.79,397.67,64.78,462.38,120,378.89c28.43-42.95,38.35-80.12,51.35-129.24,2.31-8.74,4.66-17.88,7.16-27.5q0,15.82.17,30.25c1.12,114.38,8.15,162.52,56.06,161.26,1.91,0,4.5.15,4.5.15,71.22-2.11,76.41-121.07,93.22-194.24-2.9,61.93,10.93,122.82,28.07,159.51,35.77,76.55,134.84,32.2,94.1-51.48C434.77,286.74,388.11,243.1,402.57,89.39Z" />
		<path class="cls-1" d="M464.29,173.55c5-32.82,15.74-59.57,31.08-89.06,9.26-17.8,51.47-78.58,116.47-78.58C674,5.91,695.42,85.75,642,89.5c-18.94,1.33-66.82,23.53-77.44,95.33-4.05,25.58-6.3,69.63,13.19,95.84,10.05,13.51,30,14.66,39.51-13.33,11.08-32.67,3.78-67.28-6.45-100.36-4.93-15.94-6.88-37.91,12.88-49.41,9.68-5.63,24.95-6.31,35.7,1.85,17.64,14.19,23.51,31.41,31.56,52.2,15.33,39.62,21.24,86,10.71,128-7.58,30.28-17.65,59.89-41.29,81.46-23.9,21.81-44.58,27.78-74,27.86-25.56.07-77.27-11.86-107.4-79.41-16.2-36.32-19.68-74.34-19.2-113.22C460,201.92,462.13,187.7,464.29,173.55Z" />
	</g>
</svg>
